<template>
  <div class="card-custom" v-if="ready">
    <b-card class="mt-3">
      <b-row class="text-center">        
        <b-col md="3">
          <b-form-group label="Nom du client">
            <b-form-input v-model="filters['lead.nom']" ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="text-center mt-3">
        <b-col md="3" offset-md="4">
          <b-button  v-show="loaderSearch === false" variant="primary" @click="datatableInit()">
            Recherche
          </b-button>
          <b-button  v-show="loaderSearch === true" variant="primary">
            <i class="fas fa-spinner fa-spin"></i>
          </b-button>
          <b-button class="ms-2" variant="success" @click="toExcel()" v-if="$store.api.user.role === 1"><i class="fas fa-file-excel text-white"></i></b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
      <div class="">
        <h4 class="card-title d-inline">Liste des inscrits ({{datatable.data.length}})</h4>
      </div>
      <b-table ref="datalist" stacked="md" striped hover :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
        <template #cell(logement.date_bail)="data">
          {{formatDate(data.value)}}
        </template>
        <template #cell(date_create)="data">
          {{$store.api.timestampToDate(data.value, false)}}
        </template>
        <template #cell(docs.contrat)="data">
          <b-button v-if="![null, ''].includes(data.value)" :href="data.value" target="_blank" variant="success"><i class="fas fa-file-pdf"></i></b-button>
        </template>
        <template #cell(status_id)="data">
          <b-button size="sm" :variant="$store.api.getParam(params.SouscriptionStatus, data.value).color">{{$store.api.getParam(params.SouscriptionStatus, data.value).titre}}</b-button>
        </template>
      </b-table>
      <b-pagination align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-card>
  </div>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "",
  computed:{
    date_start:{
      get(){
        var val = this.filters['min_date_create'];
        if(val === null){
          return null;
        }
        val = this.$store.api.timestampToDate(val,false)
        var tmp = val.split('-');
        if(tmp.length === 3){
          return tmp.reverse();
        }
        return null;
      },
      set(val){
        if(val === null){
          this.filters['min_date_create'] = null;
          return false;
        }
        var local = new Date(val+' 00:00:00');
        var gmt = (local.getTime() + local.getTimezoneOffset()*-60*1000)/1000;
        this.filters['min_date_create'] = Math.floor(gmt);
      }
    },
    date_end:{
      get(){
        var val = this.filters['max_date_create'];
        if(val === null){
          return null;
        }
        val = this.$store.api.timestampToDate(val,false)
        var tmp = val.split('-');
        if(tmp.length === 3){
          return tmp.reverse();
        }
        return null;
      },
      set(val){
        if(val === null){
          this.filters['max_date_create'] = null;
          return false;
        }
        var local = new Date(val+' 23:59:00');
        var gmt = (local.getTime() + local.getTimezoneOffset()*-60*1000)/1000;
        this.filters['max_date_create'] = Math.floor(gmt);
      }
    }
  },
  components: {
  },
  data: () => ({
    ready: false,
    loaderSearch:false,
    params:{
      SinistreType:[],
      SouscriptionStatus:[]
    },
    fields: [
      {
        key: 'date_create',
        label: "Date inscription",
        sortable:false,
      },
      {
        key: 'logement.date_bail',
        label: "Date effet contrat",
        sortable:false,
      },
      {
        key: 'lead.nom',
        label: "Nom",
        sortable:false,
      },
      {
        key: 'lead.prenom',
        label: "Prenom",
        sortable:false,
      },
      {
        key: 'lead.email',
        label: "Mail",
        sortable:false,
      },
      {
        key: 'lead.telephone',
        label: "Telephone",
        sortable:false,
      },
      {
        key: 'status_id',
        label: "Statut souscription",
        sortable:false,
      },
      {
        key: 'docs.contrat',
        label: "Contat",
        sortable:false,
      }
    ],
    datatable:{
      currentPage: 1,
      limitPage: 20,
      totalRow: 0,
      data: [],
    },
    sort:{
      key: 'id',
      value: 'DESC'
    },
    filters:{
      "status_id":null,
      "type_id":null,
      "id":null,
      "min_date_create":null,
      "max_date_create":null
    }
  }),
  methods: {
    datatableInit(){
      if(this.loaderSearch === true){
        return false;
      }
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.loaderSearch = true;
      this.$store.api.ajax('/partenaire/inscrit', params, (res) => {
        this.datatable = res;
        this.loaderSearch = false;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    formatDate(date){
      var tmp = date.split('-');
      if(tmp.length === 3){
        return tmp.reverse().join('/')
      }else{
        return '';
      }
    },
    toExcel(){
      var tab = this.$refs.datalist.$el;
      var rows = "Ref;Date inscription;Date effet contrat;Nom;Prenom;Mail;Telephone;Type paiement; Statut souscription";
      tab.querySelectorAll("tbody tr").forEach((tr) => {
        var row = [];
        tr.querySelectorAll("td").forEach((td) => {
          var val = td.textContent;
          if(val === ""){
            if(td.querySelectorAll("input[type='checkbox']").length > 0 && td.querySelectorAll("input")[0].value !== undefined){
              val = td.querySelectorAll("input")[0].checked
            }else if (td.querySelectorAll("input").length > 0 && td.querySelectorAll("input")[0].value !== undefined) {
              val = td.querySelectorAll("input")[0].value;
            }
          }
          row.push(val)
        });
        row = row.join(";");
        row = row.replaceAll(" ","");
        rows = rows+"\n"+row;
      });
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(rows);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Export.csv';
      hiddenElement.click();
    }
  },
  beforeMount() {
    this.$store.api.ajax('/partenaire/params', null, (res) => {
      if(res.status === true){
        res.data.SouscriptionStatus.forEach((item) => {
          item.label = item.titre;
        });
        this.params = res.data
        this.ready = true;
      }
    })
  },
  mounted() {
    this.datatableInit();
  },
}
</script>
<style>
</style>
